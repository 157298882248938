<template>
  <DashboardTemplate>
    <template v-slot:content>
      <div class="flex items-center justify-between w-full my-4 pl-4 sm:pr-4">
        <div class="mr-6">
          <h2 class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 md:leading-10 truncate">Finance dashboard</h2>
          <div class="font-medium tracking-tight text-secondary">Keep track of your financial status</div>
        </div>
        <div class="flex items-center">

          <div class="hidden sm:block">
            <Button class="mr-3 btn-outline-blue">Reports</Button>
            <Button class="mr-3 btn-outline-blue">Settings</Button>
            <Button class="mr-3 btn-blue">Export</Button>
          </div>

          <div class="sm:hidden">
            <ButtonMenu>
                <router-link to="/login" class="button-menu-link">Reports</router-link>   
                <a href="#" class="button-menu-link">Settings</a>
                <a href="#" class="button-menu-link">Export</a>
            </ButtonMenu>
          </div>
        </div>
      </div>
    </template>
  </DashboardTemplate>
</template>

<script>
export default {
  name: 'Home'
}
</script>
